const Icon = ({ name }: {name: string}) => {
  if (name === "search") {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M15.8453 17.3663C14.5006 18.3913 12.8214 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11C19 13.0713 18.2128 14.9587 16.9214 16.3794C16.9479 16.3975 16.9736 16.4175 16.9983 16.4394L21.4983 20.4394C21.8079 20.7146 21.8357 21.1887 21.5606 21.4983C21.2854 21.8079 20.8113 21.8357 20.5017 21.5606L16.0017 17.5606C15.9373 17.5033 15.8851 17.4375 15.8453 17.3663ZM17.5 11C17.5 14.5899 14.5899 17.5 11 17.5C7.41015 17.5 4.5 14.5899 4.5 11C4.5 7.41015 7.41015 4.5 11 4.5C14.5899 4.5 17.5 7.41015 17.5 11Z"
              fill="#686868" />
      </svg>
    );
  }

  else if(name === 'user'){
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M17 7C17 9.76142 14.7614 12 12 12C9.23858 12 7 9.76142 7 7C7 4.23858 9.23858 2 12 2C14.7614 2 17 4.23858 17 7ZM15.5 7C15.5 8.933 13.933 10.5 12 10.5C10.067 10.5 8.5 8.933 8.5 7C8.5 5.067 10.067 3.5 12 3.5C13.933 3.5 15.5 5.067 15.5 7Z"
              fill="#686868" />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M20 17.5C20 19.9853 16.4183 22 12 22C7.58172 22 4 19.9853 4 17.5C4 15.0147 7.58172 13 12 13C16.4183 13 20 15.0147 20 17.5ZM18.5 17.5C18.5 17.96 18.1527 18.6821 16.9215 19.3746C15.7373 20.0407 14.0003 20.5 12 20.5C9.99969 20.5 8.26273 20.0407 7.07854 19.3746C5.84733 18.6821 5.5 17.96 5.5 17.5C5.5 17.04 5.84733 16.3179 7.07854 15.6254C8.26273 14.9593 9.99969 14.5 12 14.5C14.0003 14.5 15.7373 14.9593 16.9215 15.6254C18.1527 16.3179 18.5 17.04 18.5 17.5Z"
              fill="#686868" />
      </svg>

    )
  }

  else if(name === 'instagram'){
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" stroke="#686868"
              strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M12 15.5C13.933 15.5 15.5 13.933 15.5 12C15.5 10.067 13.933 8.5 12 8.5C10.067 8.5 8.5 10.067 8.5 12C8.5 13.933 10.067 15.5 12 15.5Z"
          stroke="#686868" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17.6361 7H17.6477" stroke="#686868" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    )
  }

  else if(name === 'cart'){
      return (
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd"
                    d="M2.25 2.4375C1.93934 2.4375 1.6875 2.68934 1.6875 3C1.6875 3.31066 1.93934 3.5625 2.25 3.5625C2.86279 3.5625 3.39336 3.98811 3.52629 4.5863L3.84874 6.03731C3.85108 6.07255 3.85676 6.10779 3.86587 6.14256L3.90708 6.29985L3.95089 6.49702C3.95951 6.5358 3.97194 6.57274 3.9877 6.60755L5.24408 11.4029C5.6546 12.9698 7.07049 14.0625 8.69026 14.0625H12.5629C14.5305 14.0625 16.1254 12.4675 16.1254 10.5V8.46801C16.1254 8.0451 16.0645 7.53485 15.7775 7.08466C15.1319 6.0717 14.0055 5.4375 12.7735 5.4375H4.86789L4.6245 4.34226C4.37718 3.22933 3.39007 2.4375 2.25 2.4375ZM6.33235 11.1178L5.13886 6.5625H12.7735C13.6162 6.5625 14.3871 6.99626 14.8288 7.68934C14.9483 7.87671 15.0004 8.13486 15.0004 8.46801V10.5C15.0004 11.8462 13.9091 12.9375 12.5629 12.9375H8.69026C7.582 12.9375 6.61323 12.1899 6.33235 11.1178Z"
                    fill="#686868"/>
              <path
                  d="M12.75 16.5C13.1642 16.5 13.5 16.1642 13.5 15.75C13.5 15.3358 13.1642 15 12.75 15C12.3358 15 12 15.3358 12 15.75C12 16.1642 12.3358 16.5 12.75 16.5Z"
                  fill="#686868"/>
              <path
                  d="M7.5 15.75C7.5 16.1642 7.16421 16.5 6.75 16.5C6.33579 16.5 6 16.1642 6 15.75C6 15.3358 6.33579 15 6.75 15C7.16421 15 7.5 15.3358 7.5 15.75Z"
                  fill="#686868"/>
          </svg>

      )
  } else {
      return null
  }
};

export default Icon;